<template>
  <div class="row">
    <div class="row">
      <div class="col-sm-4">
        <div>
          <label for="name">Nazwa</label>
          <input
            id="name"
            class="form-control"
            :value="value.label"
            @input="updateLabel($event.target.value)"
          >
        </div>
      </div>
      <div class="col-sm-4">
        <div>
          <label for="start-place">Stan początkowy</label>
          <select
            id="start-place"
            class="form-control"
            v-model="value.fromStates[0]"
            @input="(event) => updateStart(event.target.value)"
          >
            <option
              v-for="option in fromStates"
              :value="option"
              :key="`from-${option.name}`"
            >
              {{ option.label }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-sm-4">
        <div>
          <label for="end-place">Stan końcowy</label>
          <select
            id="end-place"
            class="form-control"
            v-model="value.toStates[0]"
            @input="(event) => updateEnd(event.target.value)"
          >
            <option
              v-for="option in toStates"
              :value="option"
              :key="`to-${option.name}`"
            >
              {{ option.label }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4">
        <form class="flex-column">
          <label :for="`role-select-${itemIndex}`">Role:</label>
          <select
            :id="`role-select-${itemIndex}`"
            name="roles[]"
            :multiple="true"
            v-model="value.roles"
            @input="updateRoles"
          >
            <template v-for="(role) in roles">
              <option :value="role" :key="`${itemIndex}-role-${role.id}`">
                {{role.label}}
              </option>
            </template>
          </select>
          <div v-if="roles.length === 0">
            <label>Brak ról do przypisania</label>
          </div>
        </form>
        <div
          class="form-group"
          :class="{'has-error': vErrors.has('taskCategory') }"
        >
          <label>{{ vErrors.first('taskCategory') }}</label>
        </div>
      </div>
      <div class="col-sm-4">
        <form class="flex-column">
          <label :for="`events-select-${itemIndex}`">Zdarzenia:</label>
          <select
            :id="`events-select-${itemIndex}`"
            multiple
            name="events[]"
            v-model="value['transition_events']"
            @input="updateRoles"
          >
            <template v-for="(transitionEvent) in transitionEvents">
              <option :value="transitionEvent" :key="`${itemIndex}-event-${transitionEvent.id}`">
                {{transitionEvent.label}}
              </option>
            </template>
          </select>
          <div v-if="transitionEvents.length === 0">
            <label>Brak ról do przypisania</label>
          </div>
        </form>
      </div>
    </div>
    <div class="form-group checkbox">
      <div class="form-checkbox">
        <input
          :id="`appraisalBR${itemIndex}`"
          v-model="value.metadata['appraisalBR']"
          type="checkbox"
          @change="emitModificationSignal"
        >
        <label :for="`appraisalBR${itemIndex}`">Tranzycja BR</label>
      </div>
    </div>
    <!--<vue-form-generator :schema="schema" :model="value"></vue-form-generator>-->
  </div>
</template>

<script>

// import VueMultiselect from 'vue-multiselect'

export default {
  props: {
    itemIndex: {type: Number},
    value: {type: Object},
    fromStates: { type: Array, required: true },
    toStates: { type: Array, required: true },
    roles: {type: Array, required: true, default: () => []},
    transitionEvents: {type: Array, required: true, default: () => []},
  },
  components: {
    // VueMultiselect
  },
  data () {
    return {
      schema: {
        fields: [
          {
            type: 'input',
            inputType: 'text',
            label: 'Nazwa',
            model: 'label',
            maxlength: 255,
            required: true
          },
          {
            type: 'select',
            label: 'Status początkowy',
            model: 'fromStates[0]',
            required: true,
            values: this.fromStates,
            hideNoneSelectedText: true,
            noneSelectedText: ''
          },
          {
            type: 'select',
            label: 'Status końcowy',
            model: 'toStates[0]',
            required: true,
            values: this.endStates,
            hideNoneSelectedText: true
          }
        ]
      }
    }
  },
  methods: {
    emitModificationSignal () {
      this.$emit('modified')
    },
    updateLabel (value) {
      this.value.label = value
      this.$emit('input', this.value)
      this.emitModificationSignal()
    },
    updateStart (value) {
      this.$emit('input', this.value)
      this.emitModificationSignal()
    },
    updateEnd (value) {
      this.$emit('input', this.value)
      this.emitModificationSignal()
    },
    updateRoles () {
      this.$emit('input', this.value)
      this.emitModificationSignal()
    }
  }
}
</script>

<style scoped>
.input-group {
  width: 100%;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
</style>
